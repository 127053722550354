<template>
  <div class="container-realizations">
      <div class="page-loader" v-if="loading === true" id="loadPageCompo">
          <img src="/img/loading.svg" />
      </div>
    <div class="container row" id="bp">
      <h1>BP</h1>
      <div class="column">
        <img src="/img/01bp/BP-7.jpg" @click="showMultiple(4)" />
        <img src="/img/01bp/BP-11.jpg" @click="showMultiple(1)" />
      </div>
      <div class="column">
        <img src="/img/01bp/BP-8.jpg" @click="showMultiple(5)" />
        <img src="/img/01bp/BP-12.jpg" @click="showMultiple(2)" />
      </div>
      <div class="column">
        <img src="/img/01bp/BP-9.jpg" @click="showMultiple(6)" />
        <img src="/img/01bp/BP-13.jpg" @click="showMultiple(3)" />
      </div>
      <div class="column">
        <img src="/img/01bp/BP-10.jpg" @click="showMultiple(0)" />
      </div>
    </div>
    <div class="container row" id="bank">
      <h1>Bank</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-0.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(7)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(12)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(16)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-1.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(8)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(13)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(17)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-2.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(10)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(14)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(9)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(11)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/02bank_bgz/Bank-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(15)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="biblioteka">
      <h1>Biblioteka</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/03biblioteka/Biblioteka-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(18)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/03biblioteka/Biblioteka-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(19)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/03biblioteka/Biblioteka-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(20)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/03biblioteka/Biblioteka-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(21)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="domy">
      <h1>Domy</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(23)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(31)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(35)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(28)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(26)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(24)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(29)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-11.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(25)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(33)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(26)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(30)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(34)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/04domy/Domy-13.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(27)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="garderoba">
      <h1>Garderoba</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/05garderoba/Garderoba-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(36)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/05garderoba/Garderoba-2.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(37)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="kuchnieStyl">
      <h1>Kuchnie Stylowe</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(38)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(48)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(52)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(41)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(45)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(49)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(39)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-13.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(42)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(46)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(50)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-11.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(40)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-14.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(43)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(47)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(51)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(41)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/06kuchnia_stylowa/KuchniaStyl-15.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(44)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="kuchnie">
      <h1>Kuchnie</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(53)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(86)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(90)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(64)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(87)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(54)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(75)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(88)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-11.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(55)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(85)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(89)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/07kuchnie/Kuchnie-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(56)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="lada">
      <h1>Lada</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(101)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(105)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(102)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(106)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(103)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(107)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(104)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/09lada/Lada-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(108)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="lazienka">
      <h1>Łazienki</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/10lazienka/Lazienka-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(110)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/10lazienka/Lazienka-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(114)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/10lazienka/Lazienka-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(111)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/10lazienka/Lazienka-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(115)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/10lazienka/Lazienka-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(112)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/10lazienka/Lazienka-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(116)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/10lazienka/Lazienka-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(113)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="metalowe">
      <h1>Meble Metalowe</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/11metalowe/Metalowe-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(117)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/11metalowe/Metalowe-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(118)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/11metalowe/Metalowe-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(119)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/11metalowe/Metalowe-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(120)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="miniakad">
      <h1>Mini Akademik</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(122)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(156)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(181)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-13.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(126)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-17.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(130)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-21.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(135)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(133)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(157)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(123)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-14.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(127)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-18.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(131)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-22.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(136)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(144)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(167)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-11.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(124)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-15.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(128)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-19.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(132)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-23.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(137)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(155)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(178)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(125)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-16.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(129)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-20.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(134)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/12mini_akademik/MiniAkad-24.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(138)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="pokoj">
      <h1>Pokoje</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(182)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(190)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(194)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-13.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(186)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-17.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(199)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(187)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(191)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(183)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-14.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(196)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-18.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(200)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-22.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(204)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(188)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(192)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-11.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(184)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-19.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(201)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-23.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(205)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(189)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(193)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/Pokoj-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(185)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-16.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(198)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-20.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(202)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/13pokoj/pokoj-21.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(203)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="standy">
      <h1>Standy Reklamowe</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(206)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(222)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(226)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-13.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(210)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-17.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(214)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-21.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(219)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(217)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(223)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(207)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-14.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(211)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-18.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(215)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(220)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(224)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-11.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(208)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-15.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(212)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-19.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(216)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(221)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(225)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(209)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-16.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(213)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/14standy_reklamowe/Standy-20.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(218)"
        >
        </lazy-image>
      </div>
    </div>
    <div class="container row" id="szafy">
      <h1>Szafy i Szafki</h1>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-1.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(227)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-5.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(259)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-9.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(263)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-13.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(231)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-17.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(235)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-21.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(240)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-2.jpg"
          alt="Drewno"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(238)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-6.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(260)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-10.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(228)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-14.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(232)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-18.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(236)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-22.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(241)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-3.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(249)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-7.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(261)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-11.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(229)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-15.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(233)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-19.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(237)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-23.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(242)"
        >
        </lazy-image>
      </div>
      <div class="column">
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-4.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(258)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-8.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(262)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-12.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(230)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-16.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(234)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-20.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(239)"
        >
        </lazy-image>
        <lazy-image
          initial-image="/img/loading.svg"
          final-image="img/15szafy_szafki/SzaftISzafki-24.jpg"
          alt="Test1"
          :blur-amount="2"
          class="klasa"
          @click="showMultiple(243)"
        >
        </lazy-image>
      </div>
      <vue-easy-lightbox
        scrollDisabled
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import LazyImage from "./comps/LazyImage";
import axios from "axios";
import Loader from "./comps/Loader";


export default {
  components: {
    LazyImage,
    Loader,
    VueEasyLightbox,
  },
  data() {
    return {
      imgs: [], // Img Url , string or Array of string
      visible: false,
      loading: true,
      index: 0, // default: 0
    };
  },
  mounted() {
    this.images();
    this.loaderFunc();
  },
  methods: {
      loaderFunc() {
          document.getElementById("loadPageCompo").style.opacity = "0";
          setTimeout(() => {
              document.getElementById("loadPageCompo").style.opacity = "1";
              setTimeout(() => {
                          document.getElementById("loadPageCompo").style.opacity = "0";
                          setTimeout(() => {
                              document.getElementById("loadPageCompo").style.visibility="hidden";
                              this.loading = false;
                          },500)
              },3000);
          },100);
      },
    images: function () {
        axios.get("/api/images").then((response) => {
        this.resp = response.data.data;
        let ok = 0;
        for (let i = 0; i < this.resp.length; i++) {
          this.respImages = this.resp[i].images;
          for (let j = 0; j < this.respImages.length; j++) {
            // console.log(
            //   ok + ". " + this.resp[i].realisation + "/" + this.respImages[j]
            // );
            ok++;
            this.imgs.push(
              "/img/" + this.resp[i].realisation + "/" + this.respImages[j]
            );
          }
        }
      });
    },
    showMultiple(index) {
      // allow mixing

      this.index = index; // index of imgList
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
</style>
