<template>
  <div class="main-2" id="section">
    <div class="container gold mx-auto py-10">
      <div
        class="grid grid-main grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6"
      >
        <a href="/realisation#domy">
          <div
            class="
              flex
              justify-center
              flex-col
              p-3
              shadow-md
              text-center
              cursor-pointer
              product-wd
            "
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div class="product-img">
              <img src="img/04domy/Domy-4.jpg" alt="" class="rounded-lg" />
            </div>
            <div class="product-desc pt-2">
              <h2>Domy</h2>
            </div>
          </div>
        </a>
        <a href="/realisation#kuchnie">
          <div
            class="
              flex
              justify-center
              flex-col
              p-3
              shadow-md
              text-center
              cursor-pointer
              product-wd
            "
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            <div class="product-img">
              <img
                src="img/07kuchnie/Kuchnie-3.jpg"
                alt=""
                class="rounded-lg"
              />
            </div>
            <div class="product-desc pt-2">
              <h2>Kuchnie</h2>
            </div>
          </div>
        </a>
        <a href="/realisation#kuchnieStyl">
          <div
            class="
              flex
              justify-center
              flex-col
              p-3
              shadow-md
              text-center
              cursor-pointer
              product-wd
            "
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div class="product-img">
              <img
                src="img/06kuchnia_stylowa/KuchniaStyl-3.jpg"
                alt=""
                class="rounded-lg"
              />
            </div>
            <div class="product-desc pt-2">
              <h2>Kuchnie Stylowe</h2>
            </div>
          </div>
        </a>
        <a href="/realisation#miniakad">
          <div
            class="
              flex
              justify-center
              flex-col
              p-3
              shadow-md
              text-center
              cursor-pointer
              product-wd
            "
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <div class="product-img">
              <img
                src="img/12mini_akademik/MiniAkad-15.jpg"
                alt=""
                class="rounded-lg"
              />
            </div>
            <div class="product-desc pt-2">
              <h2>Akademiki</h2>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main2",
};
</script>

<style scoped>
</style>
