<template>
  <div class="main">
    <div class="overlay-black">
      <div class="overlay">
        <div class="main-desc">
          <h1>Anmar-Meble S.C</h1>
          <div class="lines">
            <span class="line-1"></span><br />
            <span class="line-2"></span>
          </div>
          <p>
            Firma Anmar-Meble S.C. rozpoczęła swoją działalność w roku 2008.
            Posiadamy wieloletnie doświadczenie w zakresie wykonywania mebli na
            wymiar wg indywidualnych projektów klienta - dbając o detale i
            najwyższą jakość materiału.
          </p>
          <p>
            Anmar-Meble świadczy usługi stolarskie zarówno dla klientów
            indywidualnych jak i klientów firmowych na terenie Polski.
          </p>
          <button><a href="/about">WIĘCEJ O NAS</a></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
};
</script>

<style scoped>
</style>
