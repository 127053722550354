<template>
  <div class="video-section">
    <div class="video-desc" data-aos="fade-up" data-aos-duration="3500">
      <h1 class="drop-shadow-lg">Zobacz Etapy Pracy</h1>
      <div class="lines">
        <span class="line-1"></span><br />
        <span class="line-2"></span>
      </div>
      <p class="drop-shadow-lg">
        Posiadamy własną kabinę lakiernicza w której przygotowujemy elementy
        mebli na własny użytek. Każdy etap pracy jest indywidualnie
        przygotowywany do zlecenia.
      </p>
    </div>
    <div class="video">
      <video src="/img/video.mp4" loop autoplay playsinline muted preload="true"></video>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main3",
};
</script>

<style scoped>
</style>
