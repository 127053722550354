<template>
  <div class="navigation">
    <div class="logo">
      <a href="/"><img src="/img/logo_alpha.png" alt="logo" /></a>
    </div>
    <nav>
      <ul class="nav-menu">
        <li><a href="/">Home</a></li>
        <li><a href="/about">O Firmie</a></li>
        <li><a href="/realisation">Realizacje</a></li>
        <li><a href="/contact">Kontakt</a></li>
      </ul>
    </nav>
    <div class="hamburger-menu">
      <span class="bar-menu"></span>
      <span class="bar-menu"></span>
      <span class="bar-menu"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navi",
};
</script>

<style scoped>
</style>
