<template>
  <div class="social">
    <ul>
      <li>
        <a href="https://www.facebook.com/Anmar-Meble-100117705656434"
          ><i class="ri-facebook-fill"></i>
          <span>Facebook</span>
        </a>
      </li>
        <li>
            <a id="icon"></a>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Main2",
};
</script>

<style scoped>
</style>
